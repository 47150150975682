import { AutocompleteValue, createFilterOptions } from "@mui/material/useAutocomplete";
import clsx from "clsx";
import { ReactNode, useCallback, useMemo } from "react";
import { Field, FieldRenderProps, UseFieldConfig } from "react-final-form";
import { useIntl } from "react-intl";
import AutocompleteInput, { AutocompleteProps as AutocompleteInputProps } from "../Autocomplete";
import {
  defaultGetOptionLabel,
  handleRenderOption,
  isSelectAllOption,
} from "../utils/autocomplete";
import { useComposedValidate } from "./utils";

type PassedThroughProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> = Omit<
  AutocompleteInputProps<T, Multiple, DisableClearable>,
  | "name"
  | "onBlur"
  | "onChange"
  | "onFocus"
  | "value"
  | "checked"
  | "error"
  | "defaultValue"
  | "type"
  | "multiple"
  | "isOptionEqualToValue"
>;

export interface FormAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> extends PassedThroughProps<T, Multiple, DisableClearable>,
    UseFieldConfig<AutocompleteValue<T, Multiple, DisableClearable, false>> {
  name: string;
  requiredError?: ReactNode;
  highlightDirty?: boolean;
  endAdornment?: ReactNode;
  key?: React.Key;
  limit?: number;
}

export type AutocompleteAdapterProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> = FieldRenderProps<AutocompleteValue<T, Multiple, DisableClearable, false>> &
  PassedThroughProps<T, Multiple, DisableClearable>;

const AutocompleteAdapter = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
>({
  input: { onChange, multiple, value, ...input },
  meta: { dirty, error },
  className,
  label,
  readOnly,
  helperText,
  highlightDirty = true,
  sx = [],
  required,
  stringify,
  inputValue,
  options,
  getOptionLabel = defaultGetOptionLabel,
  renderOption,
  limit,
  freeSolo,
  ...rest
}: AutocompleteAdapterProps<T, Multiple, DisableClearable>) => {
  const { formatMessage } = useIntl();

  const filter = useMemo(
    () => (stringify ? createFilterOptions<T>({ stringify }) : createFilterOptions<T>()),
    [stringify]
  );

  const selectAllAwareGetOptionLabel = useCallback(
    (option: T) => {
      return isSelectAllOption(option) ? option.label : getOptionLabel(option);
    },
    [getOptionLabel]
  );

  const selectAllAwareStringify = useCallback(
    (option: T) => {
      return isSelectAllOption(option) ? option.label : stringify?.(option) ?? "";
    },
    [stringify]
  );

  const selectAllStringify = stringify && selectAllAwareStringify;

  const allSelected = options.length === (value as any[])?.length;

  return (
    <AutocompleteInput
      label={required ? label ? <>{label}*</> : input.name + "*" : label ?? input.name}
      className={clsx(className, dirty && "Mbp-dirty")}
      helperText={error || helperText}
      readOnly={readOnly}
      multiple={multiple as Multiple | undefined}
      error={!!error}
      sx={[
        dirty &&
          highlightDirty && {
            ".MuiFormControl-root": { bgcolor: "highlight.dirty" },
          },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      getOptionLabel={selectAllAwareGetOptionLabel}
      getOptionDisabled={() =>
        multiple && limit && value ? (value as any[])?.length === limit : false
      }
      required={required}
      value={
        multiple && !Array.isArray(value) && value !== null && value !== undefined && value !== ""
          ? ([value] as AutocompleteValue<T, Multiple, DisableClearable, any>)
          : value
      }
      onChange={useCallback(
        (_, v, reason) => {
          if (multiple) {
            const selectAllIndex = v.findIndex(isSelectAllOption);
            if (reason === "clear") {
              return onChange([]);
            }
            if (selectAllIndex !== -1) {
              if (allSelected) {
                return onChange([]);
              } else {
                return onChange([...options]);
              }
            }
          }

          return onChange(v);
        },
        [onChange, multiple, options, allSelected]
      )}
      onInputChange={useCallback(
        //eslint-disable-next-line
        (_event, v, _reason) => {
          if (freeSolo) {
            onChange(v);
          }
        },
        [onChange, freeSolo]
      )}
      freeSolo={freeSolo}
      filterOptions={(op, params) => {
        const filtered = filter(op, params);
        if (multiple) {
          filtered.unshift({
            label: formatMessage({
              defaultMessage: "Select All",
              description: "Multiselect autocomplete select all label",
            }),
            isSelectAllOption: true,
            checked: allSelected,
          } as unknown as any);
        }
        return filtered;
      }}
      renderOption={useCallback(
        (props, option, optionState, ownerState) =>
          isSelectAllOption(option) || !renderOption
            ? handleRenderOption<T>(
                props,
                option,
                optionState,
                selectAllAwareGetOptionLabel,
                selectAllStringify
              )
            : renderOption(props, option, optionState, ownerState),
        [selectAllAwareGetOptionLabel, selectAllStringify, renderOption]
      )}
      options={options}
      {...rest}
      {...input}
    />
  );
};

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
>({
  required,
  disableClearable,
  requiredError,
  validate,
  isEqual,
  allowNull = true,
  ...rest
}: FormAutocompleteProps<T, Multiple, DisableClearable>) => (
  <Field<
    AutocompleteValue<T, Multiple, DisableClearable, false>,
    HTMLElement,
    AutocompleteValue<T, Multiple, DisableClearable, false>,
    AutocompleteAdapterProps<T, Multiple, DisableClearable>
  >
    component={AutocompleteAdapter}
    required={required || disableClearable}
    disableClearable={(required || disableClearable) as DisableClearable}
    isEqual={isEqual}
    isOptionEqualToValue={isEqual}
    allowNull={allowNull}
    {...useComposedValidate(required || disableClearable, requiredError, validate)}
    {...rest}
  />
);

export default Autocomplete;
