import { AutocompleteRenderOptionState, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import parse from "autosuggest-highlight/parse";
import { HTMLAttributes } from "react";

export type SelectAllOption = {
  label: string;
  isSelectAllOption: true;
  checked: boolean;
};

interface HighLightedItemProps extends HTMLAttributes<HTMLLIElement> {
  primary: {
    text: string;
    highlight: boolean;
  }[];
  secondary?: {
    text: string;
    highlight: boolean;
  }[][];
}

const HighlightablePart = styled("span", {
  shouldForwardProp: (propName) => propName !== "highlight",
})<{ highlight?: boolean }>((props) => [props.highlight ? { fontWeight: 700 } : false]);

const HighLightedItem = ({ primary, secondary, ...props }: HighLightedItemProps) => {
  return (
    <ListItem {...props} style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
      <ListItemText
        style={{ margin: 0, padding: 0 }}
        primaryTypographyProps={{ noWrap: true }}
        primary={
          <>
            {primary.map(({ highlight, text }, index) => (
              //eslint-disable-next-line react/no-array-index-key
              <HighlightablePart key={index} {...{ highlight, children: text.split("- &&&")[0] }} />
            ))}
          </>
        }
        secondaryTypographyProps={{ noWrap: true }}
        secondary={secondary?.map((sec, i) => (
          <Typography
            variant="body2"
            component="span"
            sx={{ display: "block" }}
            //eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            {sec.map(({ highlight, text }, index) => (
              //eslint-disable-next-line react/no-array-index-key
              <HighlightablePart key={index} {...{ highlight, children: text }} />
            ))}
          </Typography>
        ))}
      />
    </ListItem>
  );
};

export const defaultGetOptionLabel = (v: any) => v as string;

export const isSelectAllOption = (option: any): option is SelectAllOption =>
  (option as SelectAllOption).label !== undefined &&
  (option as SelectAllOption).isSelectAllOption !== undefined;

export const handleRenderOption = <T,>(
  props: HTMLAttributes<HTMLLIElement>,
  option: T,
  { inputValue }: AutocompleteRenderOptionState,
  getOptionLabel: (labelOption: T) => string,
  stringify?: (v: T) => string
) => {
  const [primary, ...secondaries] = (stringify || getOptionLabel)(option).split("\n");

  const index = primary?.toLowerCase().indexOf(inputValue.toLowerCase()) ?? -1;

  const primaryParts = parse(
    primary ?? "",
    index !== -1 && inputValue ? [[index, index + inputValue.length]] : []
  );

  let secondaryParts: any[] = [];

  if (secondaries.length !== 0) {
    secondaries.forEach((part) => {
      const partIndex = part.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) ?? -1;
      secondaryParts.push(
        parse(
          part,
          partIndex !== -1 && inputValue ? [[partIndex, partIndex + inputValue.length]] : []
        )
      );
    });
  }

  const selectAllSelectedProps = {
    sx: {
      bgcolor: "rgba(37, 135, 188, 0.08)",
      "&.Mui-focused:hover": {
        bgcolor: "rgba(37, 135, 188, 0.12)",
      },
    },
  };

  return (
    <HighLightedItem
      primary={primaryParts}
      secondary={secondaryParts ?? []}
      {...props}
      {...(isSelectAllOption(option) && option.checked && selectAllSelectedProps)}
    />
  );
};
