import { CircularProgress } from "@mui/material";
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import Popper, { PopperProps } from "@mui/material/Popper";
import { ForwardedRef, ReactNode, forwardRef, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import TextField from "./TextField";
import { defaultGetOptionLabel, handleRenderOption } from "./utils/autocomplete";

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> extends Omit<
    MuiAutocompleteProps<T, Multiple, DisableClearable, false>,
    "renderInput" | "freeSolo"
  > {
  label?: ReactNode;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  stringify?: (v: T) => string;
  freeSolo?: boolean;
  hiddenLabel?: boolean;
  variant?: "filled" | "outlined" | "standard";
  multiline?: boolean;
  maxRows?: string | number | undefined;
  required?: boolean;
  endAdornment?: ReactNode;
}

const AutocompletePopper = (props: PopperProps) => <Popper {...props} placement="bottom-start" />;

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
>(
  {
    error,
    label,
    helperText,
    readOnly,
    fullWidth,
    getOptionLabel = defaultGetOptionLabel,
    stringify,
    required,
    disableClearable,
    freeSolo,
    loading,
    loadingText,
    endAdornment,
    variant = "filled",
    hiddenLabel,
    multiline,
    maxRows,
    size,
    placeholder,
    ...rest
  }: AutocompleteProps<T, Multiple, DisableClearable>,
  ref: ForwardedRef<any>
) => (
  <MuiAutocomplete
    readOnly={readOnly}
    renderInput={({ fullWidth: _, InputLabelProps, ...params }) => {
      return (
        <TextField
          {...params}
          variant={variant}
          hiddenLabel={hiddenLabel}
          size={size}
          fullWidth={fullWidth}
          label={!hiddenLabel && label}
          error={error}
          helperText={helperText}
          readOnly={readOnly}
          maxRows={maxRows}
          multiline={multiline}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    sx={{ position: "relative", bottom: 8 }}
                  />
                ) : null}
                {endAdornment}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      );
    }}
    PopperComponent={AutocompletePopper}
    disableClearable={(disableClearable || required || readOnly) as DisableClearable}
    getOptionLabel={getOptionLabel}
    renderOption={useCallback(
      (props, option, optionState) =>
        handleRenderOption<T>(props, option, optionState, getOptionLabel, stringify),
      [getOptionLabel, stringify]
    )}
    filterOptions={useMemo(
      () => (stringify ? createFilterOptions({ stringify }) : undefined),
      [stringify]
    )}
    freeSolo={freeSolo as false}
    loading={loading}
    loadingText={loadingText ?? <FormattedMessage defaultMessage="Loading..." />}
    size={size}
    ref={ref}
    {...rest}
  />
);

export default forwardRef(Autocomplete);
